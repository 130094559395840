import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { ProductFrequencyRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import isNarrowScreen from 'lib/core/isNarrowScreen';
import { getGroupedContentfulProductsFrom } from 'lib/shared/product/contentful';
import { ReactNode, useEffect, useState } from 'react';

interface Props {
  hugText: string;
  isRequired?: boolean;
  isImportant?: boolean;
  title: string;
  subtitle?: string;
  description?: string | JSX.Element;
  products: ProductFrequencyRenewal[];
  showExpired?: boolean;
  maxProducts?: number;
  children: ReactNode;
}

/**
 * Reusable card that can be used to render products in a 'notification' card style
 */
export default function ProductCardBlock({
  hugText,
  isRequired = false,
  isImportant = false,
  title,
  subtitle,
  description,
  products,
  showExpired = false,
  maxProducts = 3,
  children,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);

  const [filtered, setFiltered] = useState<GroupedContentfulProduct[]>([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const groupedContentfulProducts = await getGroupedContentfulProductsFrom(
      products.map((pfr) => pfr.product)
    );

    setFiltered(groupedContentfulProducts);
    setIsLoading(false);
  };

  return (
    <div className={classNames('header-state-block', isRequired && 'highlight-border')}>
      <div className='state-content-wrapper'>
        <div className={classNames('content-hug-wrapper', isRequired && 'hug-required')}>
          <span className='hug-text'>{hugText}</span>
          {isImportant && <div className='hug-alert-icon' />}
        </div>

        <h3 className='content-title'>{title}</h3>
        {subtitle && <p className='content-subtitle'>{subtitle}</p>}

        {description && <p className='content-text'>{description}</p>}

        <div className='d-none d-lg-block'>{children}</div>
      </div>

      <div className='state-products-wrapper'>
        {isLoading ? (
          <>
            {Array.from({ length: isNarrowScreen() ? 1 : 2 }).map((_, index) => (
              <div className='state-product' key={index}>
                <Skeleton variant='rectangular' className='product-skeleton' />
              </div>
            ))}
          </>
        ) : (
          <>
            {/* for displaying products, we only allow a max amount of cards to show, below we check
          whether the products are equal to max amount of cards and if so then just slice up max otherwise
          we slice up to max - 1 to allow room for the filler card */}
            {filtered
              .slice(0, filtered.length === maxProducts ? maxProducts : maxProducts - 1)
              .map((gcp, index) => (
                <div className='state-product' key={index}>
                  {showExpired && <p className='product-expired-text'>Expired</p>}

                  <img
                    src={gcp.contentfulProduct.fields.photo.fields.file.url}
                    alt={gcp.contentfulProduct.fields.title}
                    className='product-img'
                  />

                  <p className='product-text'>{gcp.contentfulProduct.fields.title}</p>
                </div>
              ))}

            {/* this is the filter card that tells us whether we should display or not
             (if the above was sliced to max - 1) */}
            {filtered.length > maxProducts && (
              <div className='state-product product-extra'>
                <p className='product-text'>+ {filtered.length - maxProducts + 1} more</p>
              </div>
            )}
          </>
        )}
      </div>

      <div className='d-block d-lg-none'>{children}</div>
    </div>
  );
}
