import { UpcomingInvoice } from 'client/dist/generated/alloy';
import { ShippingMethod } from 'common/dist/models/shipping';

import { useState } from 'react';

import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import { convertCentsToDollars } from 'lib/shared/convert';

import { SummaryContent } from 'models/alloy/dashboard-base';
import classNames from 'classnames';

interface Props {
  upcomingInvoice: UpcomingInvoice;
  selectedShippingMethod?: ShippingMethod & { priceInCents: number };
}

export default function TotalsBlock({ upcomingInvoice, selectedShippingMethod }: Props) {
  const [showSummaryDetails, setShowSummaryDetails] = useState<boolean>(false);

  const { subtotal, tax, total } = upcomingInvoice;

  const subtotalToDollars = convertCentsToDollars(subtotal);
  const taxToDollars = convertCentsToDollars(tax);

  const shippingPrice = selectedShippingMethod?.priceInCents ?? 0;
  const shippingToDollars = convertCentsToDollars(shippingPrice);

  const totalToDollars = convertCentsToDollars(total + shippingPrice);

  const summaryContent: SummaryContent[] = [
    {
      title: 'Sub Total',
      content: `$${subtotalToDollars}`,
    },
    {
      title: 'Taxes',
      content: `$${taxToDollars}`,
    },
    {
      title: 'Shipping',
      content: `$${shippingToDollars}`,
    },
  ];

  const discount = upcomingInvoice.promotionCode;
  const discountExists = !!discount;
  let discountToDollars = '0.00';

  if (discountExists) {
    if (!!discount.coupon.amount_off) {
      discountToDollars = convertCentsToDollars(discount.coupon.amount_off);
    } else if (!!discount.coupon.percent_off) {
      discountToDollars = ((subtotal * (discount.coupon.percent_off / 100)) / 100).toFixed(2);
    }

    // Insert at index 1 the discount row since it should come before taxes
    summaryContent.splice(1, 0, {
      title: `Discounts (${discount.code})`,
      content: `-$${discountToDollars}`,
    });
  }

  return (
    <div className={classNames('ship-totals-block', showSummaryDetails && 'active-summary')}>
      {/* MARK: Total details wrappers */}
      <div className='ship-total-wrapper'>
        <p className='total-title'>Order Total</p>

        <button
          className='total-amount-btn'
          onClick={() => setShowSummaryDetails(!showSummaryDetails)}
        >
          <p className='total-amount'>
            ${totalToDollars}
            <img src={chevronDownIcon} alt='chevron-down' />
          </p>
        </button>
      </div>

      <div className='ship-total-summary-wrapper'>
        {summaryContent.map((summary, index) => (
          <div className='summary-row' key={index}>
            <p className='summary-title'>{summary.title}</p>
            <p className='summary-content'>{summary.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
