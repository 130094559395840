import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import {
  SubscriptionWithRenewal,
  useGetPendingMhtSwitch,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { format } from 'date-fns';

import ProductsList from '../../shared/products/ProductsList';
import SubscriptionsAction from '../SubscriptionsAction';

import { convertCentsToDollars } from 'lib/shared/convert';
import { isSubscriptionProcessing } from 'lib/shared/subscriptions';

interface Props {
  subscription: SubscriptionWithRenewal;
  setIsLoadingUpdates: (isLoading: boolean) => void;
}

export default function HeaderProductsCardBlock({ subscription, setIsLoadingUpdates }: Props) {
  const { data: isProcessing = false, isLoading: isLoadingProcessing } = useIsProcessing(
    subscription.stripeSubscriptionId
  );
  const { data: pendingMhtSwitch, isLoading: isLoadingPendingMhtSwitch } = useGetPendingMhtSwitch();

  const getTitle = () => {
    const status = subscription.status;

    switch (true) {
      case status === 'INACTIVE':
        return 'Cancelled';
      case status === 'PAUSED':
        return 'Paused';
      case isSubscriptionProcessing(isProcessing, subscription, pendingMhtSwitch):
        return 'Processing';
      default:
        return `Processes ${format(new Date(subscription.nextRecurrenceOn), 'MM/dd')}`;
    }
  };

  return (
    <div className='header-products-card-block'>
      <div className='header-content-wrapper'>
        {isLoadingProcessing || isLoadingPendingMhtSwitch ? (
          <Skeleton variant='rectangular' height={75} />
        ) : (
          <>
            <div className='content-block'>
              <p
                className={classNames(
                  'content-title',
                  subscription.status !== 'ACTIVE' && 'disable'
                )}
              >
                {getTitle()}
              </p>

              {!isSubscriptionProcessing(isProcessing, subscription, pendingMhtSwitch) && (
                <div className='d-none d-md-block'>
                  <SubscriptionsAction
                    subscription={subscription}
                    setLoading={setIsLoadingUpdates}
                  />
                </div>
              )}
            </div>

            {!!subscription.upcomingInvoice && (
              <div className='content-block d-block d-md-flex mt-3'>
                <p className='content-text'>
                  <span className='text-bold'>Total</span> $
                  {convertCentsToDollars(subscription.upcomingInvoice.total)}
                </p>
              </div>
            )}
          </>
        )}
      </div>

      <ProductsList
        subscription={subscription}
        productsWithRenewal={subscription.products}
        showDosage
        groupType='mht'
      />

      {!isSubscriptionProcessing(isProcessing, subscription, pendingMhtSwitch) && (
        <div className='d-block d-md-none'>
          <SubscriptionsAction subscription={subscription} setLoading={setIsLoadingUpdates} />
        </div>
      )}
    </div>
  );
}
