import arrowRightIcon from 'assets/svg/core/arrows/arrow-right.svg';
import calendarIcon from 'assets/svg/dashboard/home/calendar-icon.svg';
import clipboardIcon from 'assets/svg/dashboard/home/clipboard-icon.svg';
import messageIcon from 'assets/svg/dashboard/home/message-icon.svg';
import personIcon from 'assets/svg/dashboard/home/person-icon.svg';
import questionMarkIcon from 'assets/svg/dashboard/home/question-mark-icon.svg';
import classNames from 'classnames';
import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import { getAccountStatus } from 'lib/core/customer/getAccountStatus';
import { isOtcOnlyCustomer } from 'lib/core/customer/isOtcOnlyCustomer';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Action {
  icon: string;
  title: string;
  link: string;
  isExternal: boolean;
}

const DEFAULT_ACTIONS: Action[] = [
  {
    icon: clipboardIcon,
    title: 'View treatment plan',
    link: '/treatment-plan',
    isExternal: false,
  },
  {
    icon: calendarIcon,
    title: 'Manage subscriptions',
    link: '/subscriptions',
    isExternal: false,
  },
  {
    icon: messageIcon,
    title: 'Message your doctor',
    link: '/messages',
    isExternal: false,
  },
  {
    icon: personIcon,
    title: 'Refer friends & earn',
    link: '/account/referrals',
    isExternal: false,
  },
  {
    icon: questionMarkIcon,
    title: 'Need help? Contact us',
    link: 'https://myalloy.zendesk.com/',
    isExternal: true,
  },
];

const OTC_ACTIONS: Action[] = [
  {
    icon: calendarIcon,
    title: 'Manage subscriptions',
    link: '/subscriptions',
    isExternal: false,
  },
  {
    icon: personIcon,
    title: 'Refer friends & earn',
    link: '/account/referrals',
    isExternal: false,
  },
  {
    icon: questionMarkIcon,
    title: 'Need help? Contact us',
    link: 'https://myalloy.zendesk.com/',
    isExternal: true,
  },
];

export default function ActionsWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [] } = useGetAllOrders();
  const { data: treatmentPlan } = useGetTreatmentPlan();

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  // No purchase means the customer doesn't have actions to view
  if (accountStatus === 'NO_PURCHASE') return <></>;

  const hideReferrals = !customer.referralStripePromotionCodeId;

  const isOtcOnly = isOtcOnlyCustomer(subscriptions);

  const updatedActions = isOtcOnly ? OTC_ACTIONS : DEFAULT_ACTIONS;
  const filteredActions = updatedActions.filter((action) =>
    hideReferrals ? action.link !== '/account/referrals' : true
  );

  return (
    <div className={classNames('header-actions-wrapper')}>
      {filteredActions.map((action, index) => (
        <Link
          to={{ pathname: action.link }}
          className='header-action-block'
          key={index}
          target={action.isExternal ? '_blank' : undefined}
        >
          <img src={action.icon} alt={action.title} className='action-icon' />

          <p className='action-title'>
            {action.title}
            <img src={arrowRightIcon} alt='arrow right' className='title-arrow' />
          </p>
        </Link>
      ))}
    </div>
  );
}
