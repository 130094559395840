import {
  applyPromoCode,
  removePromoCode,
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { showSuccessNotification } from 'components/core/Notification';
import { useState } from 'react';
import AddressContentBlock from '../content/AddressContent';
import PaymentContentBlock from '../content/PaymentContent';
import RescheduleDrawer from '../drawers/RescheduleDrawer';
import ShipNowDrawer from '../drawers/ShipNowDrawer';
import PauseModal from '../modals/PauseModal';
import { format } from 'date-fns';

interface Props {
  subscription: SubscriptionWithRenewal;
}

export default function HeaderInfoCardBlock({ subscription }: Props) {
  const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
  const [isShipNowOpen, setIsShipNowOpen] = useState(false);
  const [isPauseOpen, setIsPauseOpen] = useState(false);

  const { mutate: mutateSubscriptions } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(subscription.stripeSubscriptionId);

  const mutate = async () => await Promise.all([mutateSubscriptions(), mutateIsProcessing()]);

  const onReschedule = () => {
    setIsRescheduleOpen(true);
  };

  const onShipNow = () => {
    setIsShipNowOpen(true);
  };

  const onPause = () => {
    setIsRescheduleOpen(false);
    setIsPauseOpen(true);
  };

  const onApplyDiscount = async (code: string) => {
    await applyPromoCode(subscription.stripeSubscriptionId, code);
    await mutate();

    showSuccessNotification('Promo code successfully applied to the next shipment');
  };

  const onRemoveDiscount = async () => {
    await removePromoCode(subscription.stripeSubscriptionId);
    await mutate();

    showSuccessNotification('Promo code successfully removed from the next shipment');
  };

  return (
    <>
      <div className='header-info-card-block'>
        <div className='header-content-wrapper'>
          <div className='content-block with-spacing'>
            <p className='content-header-title'>Next Delivery</p>

            <div className='content-btns-block d-none d-md-flex'>
              <button className='secondary-border-button' onClick={onReschedule}>
                Reschedule
              </button>

              <button className='primary-button' onClick={onShipNow}>
                Ship now
              </button>
            </div>
          </div>

          <div className='content-block'>
            <p className='content-text'>
              <span className='text-bold'>Next Shipment</span>{' '}
              {format(new Date(subscription.nextRecurrenceOn), 'MM/dd/yyyy')}
            </p>

            {/* <p className='content-text'>
              <span className='text-bold'>Last Shipment</span>{' '}
              {format(new Date(subscription.nextRecurrenceOn), 'MM/dd/yyyy')}
            </p> */}

            <p className='content-text'>
              <span className='text-bold'>Total</span> $
              {(subscription.upcomingInvoice?.total ?? 0) / 100}
            </p>
          </div>
        </div>

        <div className='info-content-wrapper'>
          <AddressContentBlock />
          <PaymentContentBlock />
        </div>

        <div className='content-btns-block d-grid d-md-none'>
          <button className='secondary-border-button' onClick={onReschedule}>
            Reschedule
          </button>

          <button className='primary-button' onClick={onShipNow}>
            Ship now
          </button>
        </div>
      </div>

      {/* DRAWERS */}
      <RescheduleDrawer
        subscription={subscription}
        open={isRescheduleOpen}
        setOpen={setIsRescheduleOpen}
        onPause={onPause}
      />
      <ShipNowDrawer shipment={subscription} open={isShipNowOpen} setOpen={setIsShipNowOpen} />

      {/* MODALS */}
      <PauseModal shipment={subscription} open={isPauseOpen} setOpen={setIsPauseOpen} />
    </>
  );
}
