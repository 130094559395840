import { getMdiWidget } from 'client/dist/generated/alloy';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from 'redux';

import { updateMdiWidget } from 'actions/core/customer_actions';

import Banner from 'components/core/Banner';

/**
 * only used in the messages tab for when we need to communicate certain copy to customers
 * via the messages tab when things could be down, some specific info, etc
 */
export default function MessagesBanner() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { messagesBanner } = useAppSelector((state) => state.alloy);

  const dispatchUpdateMdiWidget = bindActionCreators(updateMdiWidget, dispatch);

  const isMessages = pathname === '/messages';

  useEffect(() => {
    const fetchWidget = async () => {
      try {
        const widget = await getMdiWidget();
        dispatchUpdateMdiWidget(widget);
      } catch (e) {
        dispatchUpdateMdiWidget({ status: 'PENDING' });
      }
    };

    fetchWidget();
  }, []);

  const mdiWidget = useAppSelector((state) => state.alloy.mdiWidget);

  if (mdiWidget.status === 'INACTIVE' || !isMessages || pathname.includes('-confirmation')) {
    return <></>;
  }

  return <Banner overrideBanner={messagesBanner} />;
}
