import { useGetAllUnreadNotifications } from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import logo from 'assets/svg/logoDark.svg';

import { logout } from 'actions/auth/logout_actions';

import MessagesBanner from './MessagesBanner';

import NotificationNavLink from 'components/notifications/NotificationNavLink';
import Popover from 'components/notifications/Popover';
import { NAVIGATION_TABS } from 'data/core/dashboard-nav';
import { NAVIGATIONS_TABS_V3 } from 'data/core/dashboard-nav-v3';
import NotificationDrawer from 'components/notifications/NotificationDrawer';

export type CurrentPage =
  | 'home'
  | 'overview'
  | 'treatment plan'
  | 'shipments'
  | 'subscriptions'
  | 'messages'
  | 'account'
  | 'settings'
  | 'notifications';

interface Props {
  currentPage?: CurrentPage;
}

export default function DashboardNavBar({ currentPage }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const showDashV3 = process.env.REACT_APP_SHOW_V3_HOME === 'true';

  const navigationTabs = showDashV3 ? NAVIGATIONS_TABS_V3 : NAVIGATION_TABS;

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const dispatchLogout = bindActionCreators(logout, dispatch);

  useEffect(() => {
    document.body.classList.remove('openMenu');
  }, []);

  const toggleMobileNav = () => {
    if (document.body.classList.contains('openMenu')) {
      document.body.classList.remove('openMenu');
    } else {
      document.body.classList.add('openMenu');
    }
  };

  const logoutUser = async () => {
    dispatchLogout(history);
  };

  return (
    <div className='navbar-dark nav-main light'>
      <nav
        id='navbar-main'
        className='navbar navbar-fixed-top navbar-main navbar-expand-xl'
        aria-label='Primary navigation'
      >
        <div className='container align-items-center'>
          <div className='d-flex ml-3 ml-lg-0 logo-wrapper align-items-center'>
            <Link to='/' className='navbar-brand'>
              <img className='logo' alt='Alloy logo' src={logo} />
            </Link>
          </div>

          <div className='d-flex mr-3 mr-lg-0'>
            <div
              className='dashboard-nav-notification d-xl-none d-block'
              onClick={() => setIsNotificationsOpen(true)}
            >
              <NotificationNavLink />
            </div>

            <button onClick={toggleMobileNav} id='menu' className='d-block d-xl-none'>
              <span className='menu-bars light' />
            </button>
          </div>

          <div className='d-xl-flex align-items-center navigation'>
            <ul className='dashboard-nav'>
              {navigationTabs.map((tab, index) => (
                <li
                  className={`dashboard-nav-item ${tab.title === currentPage ? 'selected' : ''}`}
                  key={index}
                  onClick={toggleMobileNav}
                >
                  <Link to={tab.link} className='dashboard-nav-link'>
                    {tab.title}
                  </Link>
                </li>
              ))}

              <Popover
                trigger={<NotificationNavLink />}
                className='dashboard-nav-notification d-none d-xl-block'
                onOpenDrawer={() => setIsNotificationsOpen(true)}
              />

              <li className='dashboard-nav-item is-logout'>
                <a
                  onClick={logoutUser}
                  className='dashboard-nav-link orange'
                  style={{ cursor: 'pointer' }}
                >
                  logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <MessagesBanner />
      <NotificationDrawer open={isNotificationsOpen} setOpen={setIsNotificationsOpen} />
    </div>
  );
}
