import { ProductAndFrequency } from 'client/dist/generated/alloy';
import DomProductRegistry, {
  productFrequencyTranslator,
} from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { RecurrenceType } from 'common/dist/products/productFrequency';

/**
 * Combining the two functions above for getGroupedProductsFor and getContentfulProductsFor, we
 * get everything in one from some product frequencies in order to display in various
 * places across the app (treatment plan at the time of writing this)
 *
 * @param pfs ProductAndFrequency[]
 * @returns GroupedContentfulProduct[]
 */
export const getGroupedContentfulProductsFrom = async (
  pfs: ProductAndFrequency[]
): Promise<GroupedContentfulProduct[]> => {
  const groupedProducts = await DomProductRegistry.get().getGroupedProductsFor(
    pfs.map(productFrequencyTranslator)
  );

  return await DomProductRegistry.get().getContentfulProductsFor(groupedProducts);
};

/**
 *
 *
 * @param pfs ProductAndFrequency[]
 * @param shouldGroupAll boolean
 * @returns GroupedContentfulProduct[]
 */
export const getGroupedContentfulProductsBy = async (
  pfs: ProductAndFrequency[],
  shouldGroupAll: boolean
): Promise<GroupedContentfulProduct[]> => {
  if (shouldGroupAll) {
    const grouped = await getGroupedContentfulProductsFrom(pfs);

    return grouped;
  } else {
    const grouped = await DomProductRegistry.get().getRecurringProductsForV2(
      pfs.map((pf) => pf.productId)
    );

    return grouped.flat();
  }
};

/**
 * given product frequencies that already fetched eligible products (those that a customer has not
 * purchased or are active), we use our product registry to get all contentful upsell products then filter
 * those contentful upsell products against the product frequencies provided and use our other registry
 * methods to fetch the grouped contentful products (at the moment, upsell will not have child but this
 * is setting us up for the future if there ever will be a case of it!)
 *
 * @param products ProductAndFrequency[]
 * @returns GroupedContentfulProduct[]
 */
export const getUpsellGroupedContentfulProducts = async (
  products: ProductAndFrequency[]
): Promise<GroupedContentfulProduct[]> => {
  const upsellContentfulProducts = await DomProductRegistry.get().getUpsellContentfulProducts();

  const filteredProducts = products.filter((pf) =>
    pf.contentfulProductId
      ? upsellContentfulProducts
          .sort((a, b) => b.fields.order - a.fields.order)
          .map((ucp) => ucp.sys.id)
          .includes(pf.contentfulProductId)
      : false
  );

  return await getGroupedContentfulProductsFrom(filteredProducts);
};
