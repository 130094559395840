import AddressDrawer from 'components/dashboard/account/drawers/AddressDrawer';
import { useState } from 'react';
import { useAppSelector } from 'reducers/alloy_reducer';

export default function AddressContentBlock() {
  const [isOpen, setIsOpen] = useState(false);

  const customer = useAppSelector((state) => state.alloy.customer!);

  return (
    <>
      <div className='content-block'>
        <p className='content-text'>
          <span className='text-bold'>Shipping Address</span>
        </p>

        <p className='content-text'>
          {customer.shippingAddressLineOne} {customer.shippingAddressLineTwo}
        </p>
        <p className='content-text'>
          {customer.city}, {customer.stateAbbr} {customer.zip}
        </p>

        <button className='content-link' onClick={() => setIsOpen(true)}>
          Update
        </button>
      </div>

      <AddressDrawer open={isOpen} setOpen={setIsOpen} />
    </>
  );
}
