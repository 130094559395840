import DashboardContainer from 'containers/dashboard/DashboardContainer';

import TitleTextWrapper from 'components/dashboard/shared/wrappers/TitleTextWrapper';
import ManageWrapper from 'components/dashboard/subscriptions/wrappers/ManageWrapper';

export default function ManageSubscription() {
  return (
    <DashboardContainer title='Upcoming Subscription | Alloy' desc='' currentPage='subscriptions'>
      <section className='dashboard-section'>
        <div className='container'>
          <TitleTextWrapper title='Manage Subscription' />

          <ManageWrapper />
        </div>
      </section>
    </DashboardContainer>
  );
}
