import { getMdiWidget, useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateMdiWidget } from 'actions/core/customer_actions';

import Loader from 'components/core/Loader';
import EmptyState from 'components/dashboard/EmptyState';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import { isOtcOnlyCustomer } from 'lib/core/customer/isOtcOnlyCustomer';
import useMarkNotificationsAsRead from 'lib/dashboard/notifications/useMarkNotificationsAsRead';
import { useAppSelector } from 'reducers/alloy_reducer';
import TitleTextWrapper from 'components/dashboard/shared/wrappers/TitleTextWrapper';

export default function Messages() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['NEW_DOCTOR_MESSAGE']);

  const dispatch = useDispatch();

  const [isLoadingWidget, setIsLoadingWidget] = useState(true);

  const customer = useAppSelector((state) => state.alloy.customer!!);
  const mdiWidget = useAppSelector((state) => state.alloy.mdiWidget);

  const { data: subscriptions = [], isLoading: isLoadingSubs } =
    useGetAllSubscriptionsForCustomer();

  const dispatchUpdateMdiWidget = bindActionCreators(updateMdiWidget, dispatch);

  const isLoading = isLoadingSubs || isLoadingWidget;

  useEffect(() => {
    const fetchWidget = async () => {
      try {
        const widget = await getMdiWidget();
        dispatchUpdateMdiWidget(widget);
        setIsLoadingWidget(false);
      } catch (e) {
        dispatchUpdateMdiWidget({ status: 'PENDING' });
        setIsLoadingWidget(false);
      }
    };

    fetchWidget();
  }, []);

  if (isLoading)
    return (
      <DashboardContainer title='Messages | Alloy' desc='' currentPage='messages'>
        <Loader />
      </DashboardContainer>
    );

  let content = <></>;

  if (mdiWidget.status === 'ACTIVE' && mdiWidget.url) {
    content = (
      <div className='row mt-4'>
        <div className='col-12'>
          <iframe
            allow='microphone'
            className='messages-iframe'
            src={mdiWidget.url}
            title='mdi'
          ></iframe>
        </div>
      </div>
    );
  } else if (
    customer.status === 'NEW' ||
    (mdiWidget && mdiWidget.status === 'INACTIVE') ||
    isOtcOnlyCustomer(subscriptions)
  ) {
    content = <EmptyState type='NEW_CONSULT' />;
  } else if (customer.status === 'PENDING') {
    content = <EmptyState type='PENDING' />;
  }

  return (
    <DashboardContainer title='Messages | Alloy' desc='' currentPage='messages'>
      <section className='dashboard-section'>
        <div className='container'>
          <TitleTextWrapper
            title='Messages'
            description={
              <>
                If you have questions about your symptoms, medical history, or prescription, please
                message your doctor here. Your doctor will respond within 24-48 hours during normal
                business hours. For non-medical questions (shipping, billing, refills, etc.) please
                email{' '}
                <a href='mailto:support@myalloy.com' className='anchor-link'>
                  support@myalloy.com
                </a>
                .
              </>
            }
          />

          {content}
        </div>
      </section>
    </DashboardContainer>
  );
}
