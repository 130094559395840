import { ExperienceCategory } from 'common/dist/models/experience';
import { sub } from 'date-fns';
import { store } from 'store';
import { RootState } from 'reducers';

export const validateCategories = (categories: string[]): ExperienceCategory[] => {
  if (categories.length === 0) return ['mht'];

  const allCategories: ExperienceCategory[] = [
    'mht',
    'vaginal-health',
    'skin-health',
    'sexual-health',
    'gut-health',
    'hair-health',
    'renewal',
  ];

  const filteredCategories: ExperienceCategory[] = allCategories.filter((c) =>
    categories.includes(c)
  );

  if (filteredCategories.length === 0) return ['mht'];

  return filteredCategories;
};

export const isStepValidated = (
  key: ExperienceValidationKey,
  hasRecentSubmission: boolean,
  categories: ExperienceCategory[]
) => {
  const state = store.getState() as RootState;

  const { isAuthenticated, customer } = state.alloy;
  const { localPreCustomer, licenseExists } = state.experience;

  switch (key) {
    case ExperienceValidationKey.name:
      return (
        (localPreCustomer.firstName || (customer && customer.firstName)) &&
        (localPreCustomer.state || (customer && customer.stateAbbr))
      );

    case ExperienceValidationKey.register:
    case ExperienceValidationKey.verification:
      return isAuthenticated;

    case ExperienceValidationKey.intake:
      return hasRecentSubmission;

    case ExperienceValidationKey.uploadId:
      return licenseExists;

    case ExperienceValidationKey.uploadVideo:
      return true; // this page is optional so users aren't required to get back to it

    // [ab-test:replace-first-step-with-relief-type]
    case ExperienceValidationKey.standaloneState:
      return localPreCustomer.state || (customer && customer.stateAbbr);

    // steps that we just need the customer to view
    case ExperienceValidationKey.reliefType:
    case ExperienceValidationKey.expectations:
    case ExperienceValidationKey.mhtPreferences:
    case ExperienceValidationKey.addOnPreferences:
    case ExperienceValidationKey.review:
    case ExperienceValidationKey.renewalConfirmation:
      return isStepViewed(key, categories);

    default:
      return false;
  }
};

/**
 * validates that a customer has viewed a specific step in a certain list of categories within the last 2 weeks.
 * this allows to get back to a certain page if the customer comes back / refreshes the page without having
 * to do anything funky to get around it (some pages we just need people to 'view' and continue on without getting
 * any form of data from them)
 *
 * @param key ExperienceValidationKey
 * @param categories ExperienceCategory[]
 * @returns boolean
 */
const isStepViewed = (key: ExperienceValidationKey, categories: ExperienceCategory[]): boolean => {
  const state = store.getState() as RootState;

  const { localPreCustomer } = state.experience;

  if (!localPreCustomer.experienceValidation) {
    return false;
  }

  const isCategoriesValidated = localPreCustomer.experienceValidation.categories.every((c) =>
    categories.includes(c)
  );

  const twoWeeksBeforeNow = sub(new Date(), { weeks: 2 });

  const isNotExpired =
    !!localPreCustomer.experienceValidation.date &&
    twoWeeksBeforeNow <= new Date(localPreCustomer.experienceValidation.date);

  return (
    isNotExpired &&
    isCategoriesValidated &&
    localPreCustomer.experienceValidation.stepsViewed.includes(key)
  );
};

export enum ExperienceValidationKey {
  name = 'name',
  reliefType = 'relief_type',
  register = 'register',
  verification = 'verification',
  intake = 'intake',
  uploadId = 'upload_id',
  uploadVideo = 'upload_video',
  review = 'review',

  checkout = 'checkout',
  orderConfirmation = 'order_confirmation',

  expectations = 'expectations',
  mhtPreferences = 'mht_preferences',
  addOnPreferences = 'add_on_preferences',

  renewal = 'renewal',
  renewalConfirmation = 'renewal_confirmation',

  standaloneState = 'standalone_state',
}
