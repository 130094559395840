import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { differenceInDays, format } from 'date-fns';
import { minBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { clearCart } from 'actions/checkout-experience/cart_actions';

import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';
import { EmailUs } from 'components/core/Buttons';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import HeaderBlock from 'components/dashboard/treatment-plan/core/HeaderBlock';
import ProgressBlock from 'components/dashboard/treatment-plan/core/ProgressBlock';
import ReferralBlock from 'components/dashboard/treatment-plan/core/ReferralBlock';
import TopBlogBlock from 'components/dashboard/shared/blocks/TopBlogBlock';
import InfoSection from 'components/dashboard/shared/sections/Info';
import TestimonialsSection from 'components/dashboard/shared/sections/Testimonials';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import { getGroupedContentfulProductsFrom } from 'lib/shared/product/contentful';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function RequestConfirmation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const { alloyCart: cart, checkout } = useAppSelector((state) => state.experience);
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const nextShipment = minBy(subscriptions, (sub) => new Date(sub.nextRecurrenceOn));
  const nextRecurrence = nextShipment
    ? format(new Date(nextShipment.nextRecurrenceOn), 'MM/dd/yyyy')
    : undefined;
  const recurrenceDaysDiff = nextShipment
    ? Math.abs(differenceInDays(new Date(), new Date(nextShipment.nextRecurrenceOn)))
    : 0;

  const [shipmentProducts, setShipmentProducts] = useState<GroupedContentfulProduct[]>([]);

  const dispatchClearCart = bindActionCreators(clearCart, dispatch);

  // if a customer has a prescription for something they are switching over to,
  // we don't need to display content that focuses around waiting for doc
  // since we know they will be all set with the sub products swapped
  const hasPrescription =
    searchParams.has('hasPrescription') && searchParams.get('hasPrescription') === 'true';

  useEffect(() => {
    if (cart.products.length === 0) {
      dispatchClearCart();

      history.replace('/');
    }
  });

  useEffect(() => {
    const fetchProducts = async () => {
      const products =
        nextShipment?.products
          .filter((pfr) => pfr.product.category !== 'mht')
          .map((pfr) => pfr.product) ?? [];

      const groupedContentful = await getGroupedContentfulProductsFrom(products);

      setShipmentProducts(groupedContentful);
    };

    fetchProducts();
  }, []);

  const getProductHug = () => {
    switch (cart.checkoutType) {
      case 'CUSTOMER_FORM_FACTOR_CHANGE': {
        if (hasPrescription) {
          return 'New treatment';
        } else {
          return 'Requested from doctor';
        }
      }
      case 'CUSTOMER_DOSAGE_CHANGE': {
        return 'New dose requested';
      }
      default: {
        return 'Requested from doctor';
      }
    }
  };

  return (
    <DashboardContainer title='Request Confirmation | Alloy' desc=''>
      <HeaderBlock
        title={hasPrescription ? 'Your treatment change is' : 'Your request has been'}
        fancyTitle={hasPrescription ? 'confirmed' : 'received'}
        backgroundType='secondary'
      />

      <SectionWrapper sectionClasses='order-confirmation-section'>
        {checkout && <div className='fp-checkout-id' data-checkout-id={checkout.id} />}

        <div className='col-12 col-md-7'>
          {hasPrescription && nextRecurrence && (
            <p className='confirmation-text text-bold'>
              Your order will be charged on {nextRecurrence} and ships in {recurrenceDaysDiff} days.
            </p>
          )}

          {cart.products.map((gcp, index) => (
            <ViewableProduct
              key={index}
              groupedProduct={gcp}
              showDosage={cart.checkoutType === 'CUSTOMER_FORM_FACTOR_CHANGE'}
              hideDetails
              hideProductInformation
              hidePrice={cart.checkoutType === 'CUSTOMER_DOSAGE_CHANGE'}
            >
              <span className='product-hug'>{getProductHug()}</span>
            </ViewableProduct>
          ))}

          {hasPrescription &&
            shipmentProducts.map((gcp, index) => (
              <ViewableProduct
                key={index}
                groupedProduct={gcp}
                showDosage={cart.checkoutType === 'CUSTOMER_FORM_FACTOR_CHANGE'}
                hideDetails
                hideProductInformation
                hidePrice={cart.checkoutType === 'CUSTOMER_DOSAGE_CHANGE'}
              />
            ))}

          {hasPrescription ? (
            <>
              <p className='confirmation-text'>
                Thank you for your order! You'll receive an order confirmation email shortly at{' '}
                <span>{customer.email}</span>.
              </p>

              <p className='confirmation-text'>
                Any questions about your order at all, email us at{' '}
                <EmailUs btnClass='text-underline' />. We're here for you!
              </p>
            </>
          ) : (
            <ProgressBlock
              title="What's next."
              description='Your doctor will review your request and, once approved, your new product will ship with the rest of your normal shipment.'
              barTitles={['Request', 'Doctor review', 'Shipped']}
              currentIndex={1}
              hideButton
            >
              <p className='progress-text'>
                Any questions about your order at all, email us at{' '}
                <EmailUs btnClass='text-underline' />. We're here for you!
              </p>
            </ProgressBlock>
          )}
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
