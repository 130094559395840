import {
  GetShippingMethods200Item,
  ShippingMethodType,
  useGetAllSubscriptionsForCustomer,
  useGetShippingMethods,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import { convertCentsToDollars } from 'lib/shared/convert';
import { cleanShippingTitle } from 'lib/shared/shipping';
import classNames from 'classnames';
import { ShippingSpeed, ShippingSpeedType } from 'common/dist/models/shipping';
import { shippingSpeeds } from 'data/request-experience/shipping-speed';
import { first, minBy } from 'lodash';
import { format } from 'date-fns';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { getProductPrice } from 'lib/shared/product/pricing';
import { getProductIdsFromGroupedProducts, getProductToBeBundledWith } from 'lib/shared/product';
import {
  cleanGroupedPurchasableProducts,
  cleanPurchasableProducts,
} from 'lib/request-experience/flow';
import { useLocation } from 'react-router';
import { updateTPCart } from 'actions/treatment-plan/cart_actions';
import { useAppSelector } from 'reducers/alloy_reducer';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

interface Props {
  hideBorder?: boolean;
}

export default function ShipOptionBlock({ hideBorder = false }: Props) {
  const location = useLocation();

  const dispatch = useDispatch();

  const tpCart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);
  const cart = useAppSelector((state) => state.experience.alloyCart);
  const customer = useAppSelector((state) => state.alloy.customer!!);

  const stateAbbr = customer.stateAbbr ?? '';

  const { data: shippingMethods = [] } = useGetShippingMethods(stateAbbr);
  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();

  const [showShippingDetails, setShowShippingDetails] = useState<boolean>(false);
  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');
  const [selectedShippingSpeed, setSelectedShippingSpeed] =
    useState<ShippingSpeedType>('NEXT_SHIPMENT');
  const [parentSelected, setParentSelected] = useState<GroupedContentfulProduct | undefined>(
    undefined
  );
  const [discountValue, setDiscountValue] = useState<number>(0);

  const selectedShippingMethod = shippingMethods.find((sm) => sm.method === selectedShippingType);
  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const activeSubscriptionsProducts = activeSubs
    .flatMap((sub) => sub.products)
    .map((p) => p.product);
  const nextRecurrence = minBy(activeSubs, (sub) => new Date(sub.nextRecurrenceOn));
  const nextShipmentDate =
    nextRecurrence && !isLoadingSubscriptions
      ? `(${format(new Date(nextRecurrence.nextRecurrenceOn), 'MM/dd')})`
      : '';

  const isRequestExperience = location.pathname.includes('request-experience');

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);
  const dispatchUpdateTPCart = bindActionCreators(updateTPCart, dispatch);

  useEffect(() => {
    const getDiscountPrice = async () => {
      const selectedProducts = isRequestExperience ? cart.products : tpCart.products;
      // only one item in cart
      // only continue and get the discount to show if the product in cart is a bundle product, otherwise don't show it
      if (selectedProducts.length === 1 && (await isBundledCart(selectedProducts))) {
        const product = first(selectedProducts[0].alloyProduct.parent);

        if (product) {
          const productId = product.productId;

          const [unbundledPrice, bundledPrice] = await Promise.all([
            getProductPrice(productId, product.recurrenceType),
            getProductPrice(productId, product.recurrenceType, true),
          ]);

          // if is a product that has a bundled price, we set it, otherwise keep it as 0 and we won't show the tag
          if (unbundledPrice && bundledPrice) {
            const differenceInPrice = unbundledPrice / 100 - bundledPrice / 100;
            setDiscountValue(differenceInPrice);
          }
        }
      }
    };

    getDiscountPrice();
  }, []);

  useEffect(() => {
    const getParentSelected = async () => {
      const selectedProducts = isRequestExperience ? cart.products : tpCart.products;

      // only one item in cart
      // only continue and get the parent's name to show if the product in cart is a bundle product, otherwise don't show it
      if (selectedProducts.length === 1 && (await isBundledCart(selectedProducts))) {
        const productIds = getProductIdsFromGroupedProducts(selectedProducts);

        const cartProducts = await ProductRegistry.get().getRecurringProductsForV2(productIds);
        const subProducts = await ProductRegistry.get().getRecurringProductsForV2(
          activeSubscriptionsProducts.map((p) => p.productId)
        );

        const cartAndSubProducts = [...cartProducts, ...subProducts].flat();

        const parent = getProductToBeBundledWith(cartProducts.flat()[0], [cartAndSubProducts]);

        if (parent) {
          setParentSelected(parent);
        }
      }
    };

    getParentSelected();
  }, []);

  const isBundledCart = async (selectedProducts: GroupedContentfulProduct[]) => {
    const selectedProductIds = getProductIdsFromGroupedProducts(selectedProducts);
    const subProductIds = activeSubscriptionsProducts.map((p) => p.productId);

    const purchasableProducts = await cleanPurchasableProducts(selectedProductIds, subProductIds);
    return purchasableProducts.some((gcp) => gcp.alloyProduct.parent[0].isBundledPrice);
  };

  const updateCartBasedOnShippingSpeed = async (shippingSpeed: ShippingSpeedType) => {
    const selectedProducts = isRequestExperience ? cart.products : tpCart.products;

    const cartProductIds = getProductIdsFromGroupedProducts(selectedProducts);
    const subProductIds = activeSubscriptionsProducts.map((p) => p.productId);

    const cleanedProducts =
      shippingSpeed === 'NEXT_SHIPMENT'
        ? await cleanGroupedPurchasableProducts(cartProductIds, subProductIds)
        : await ProductRegistry.get().getRecurringProductsForV2(cartProductIds);

    if (isRequestExperience) {
      dispatchUpdateCart({
        products: cleanedProducts.flat(),
        shipNow: shippingSpeed === 'SHIP_NOW',
      });
    } else {
      dispatchUpdateTPCart({
        products: cleanedProducts.flat(),
        shipNow: shippingSpeed === 'SHIP_NOW',
      });
    }
  };

  const handleSelectingShipping = (sm: GetShippingMethods200Item) => {
    dispatchUpdateCart({
      shipping: sm,
    });

    setSelectedShippingType(sm.method);
  };

  const onSelectShippingSpeed = async (ss: ShippingSpeed) => {
    await updateCartBasedOnShippingSpeed(ss.shippingSpeedType);

    setSelectedShippingSpeed(ss.shippingSpeedType);
  };

  const cleanSpeedTypeDescription = (ss: ShippingSpeed) => {
    if (ss.shippingSpeedType === 'NEXT_SHIPMENT') {
      return (
        <p className='option-title'>
          {`${ss.description} ${parentSelectedName} order ${nextShipmentDate}`}
          {!!discountValue && (
            <span className='next-shipment-discount-tag'>{`Get $${Number(
              discountValue.toFixed(2)
            )} off`}</span>
          )}
        </p>
      );
    }

    return <p className='option-title'>{ss.description}</p>;
  };

  const parentSelectedName = parentSelected
    ? parentSelected.alloyProduct.parent.map((p) => p.name).join(', ')
    : '';

  return (
    <div
      className={classNames(
        're-shipping-method-block',
        showShippingDetails && ' re-active-shipping'
      )}
    >
      <div className='re-shipping-options-wrapper'>
        <p className='shipping-header-title'>Shipping Speed</p>

        {shippingSpeeds.map((ss, index) => (
          <div
            key={index}
            className={`shipping-option ${
              ss.shippingSpeedType === selectedShippingSpeed ? 'selected' : ''
            }`}
            onClick={() => onSelectShippingSpeed(ss)}
          >
            <div className='option-outer-circle'></div>

            <div className='option-content'>{cleanSpeedTypeDescription(ss)}</div>
          </div>
        ))}
      </div>

      {selectedShippingSpeed === 'SHIP_NOW' && (
        <>
          <div className={classNames('re-shipping-method-wrapper', hideBorder && 're-hide-border')}>
            <div className='shipping-content-header'>
              <div
                className={`shipping-content ${showShippingDetails ? 'open' : 'closed'}`}
                onClick={() => setShowShippingDetails(!showShippingDetails)}
              >
                {showShippingDetails ? (
                  <>
                    <p className='shipping-text'>
                      Processing time for orders can take 1-3 business days.
                    </p>

                    <img src={chevronDownIcon} alt='chevron right' className='shipping-chevron' />
                  </>
                ) : (
                  <>
                    <p className='shipping-text'>
                      {selectedShippingMethod
                        ? cleanShippingTitle(selectedShippingMethod)
                        : 'Standard Shipping (3-5 business days)'}
                    </p>

                    <div className='shipping-dropdown'>
                      <p className='shipping-text-bold'>
                        {selectedShippingMethod
                          ? selectedShippingMethod.method === 'STANDARD'
                            ? 'FREE'
                            : `$${convertCentsToDollars(selectedShippingMethod.priceInCents)}`
                          : 'FREE'}
                      </p>

                      <img src={chevronDownIcon} alt='chevron right' className='shipping-chevron' />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='re-shipping-options-wrapper shipping-method-options'>
            {shippingMethods
              .sort((a, b) => a.priceInCents - b.priceInCents)
              .map((sm, index) => (
                <div
                  key={index}
                  className={`shipping-option ${
                    sm.method === selectedShippingType ? 'selected' : ''
                  }`}
                  onClick={() => handleSelectingShipping(sm)}
                >
                  <div className='option-outer-circle'></div>

                  <div className='option-content'>
                    <p className='option-title'>{cleanShippingTitle(sm)}</p>
                    <p className='option-price'>
                      {sm.method === 'STANDARD'
                        ? 'FREE'
                        : `$${convertCentsToDollars(sm.priceInCents)}`}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}
