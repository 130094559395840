import { uploadIdentificationPhoto } from 'client/dist/generated/alloy';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import { Form } from 'rsuite';

import { updateLicenseExists } from 'actions/core/customer_actions';

import { imageProblemMessage } from 'common/dist/license-uploads/isTooBig';
import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import IdentityBlock from 'components/checkout-experience/verify-identity/IdentityBlock';
import PhotoModal from 'components/checkout-experience/verify-identity/PhotoModal';
import { UploadField } from 'components/core/fields/UploadField';
import Loader from 'components/core/Loader';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { b64toBlob, isFileValid } from 'lib/checkout-experience/identity/file';
import isNarrowScreen from 'lib/core/isNarrowScreen';
import { showErrorNotification } from 'components/core/Notification';

import { ExperienceComponentModel } from 'models/alloy/experience';

export default function VerifyIdentity({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);

  const dispatchUpdateLicenseExists = bindActionCreators(updateLicenseExists, dispatch);

  const onSubmit = async () => {
    try {
      if (fileBlob) {
        setIsLoading(true);

        await uploadIdentificationPhoto({
          file: fileBlob,
        });

        // Update the redux value to true since we uploaded their id / license
        dispatchUpdateLicenseExists(true);

        onNext();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onRemove = () => {
    setFileBlob(null);
  };

  const onClose = (photo?: string) => {
    setShowPhotoModal(false);

    if (photo) {
      const blob = b64toBlob(photo);
      setFileBlob(blob);
    }
  };

  return (
    <Layout title='Verify Identity - Alloy' desc='' noBars>
      <TopBannerWithProgress
        onBack={isLoading ? undefined : onBack}
        percentComplete={getPercentComplete(location)}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <CheckoutExperienceSection>
            <h1 className='content-title'>Verify your identity</h1>
            <p className='content-text-bold'>Upload a photo ID</p>

            <IdentityBlock file={fileBlob} onRemove={onRemove} />
          </CheckoutExperienceSection>

          <BottomBar>
            {fileBlob ? (
              <button className='primary-button' onClick={onSubmit}>
                Next
              </button>
            ) : (
              <div className='buttons-list-wrapper'>
                <UploadField
                  name='validId'
                  onChange={async (blobs) => {
                    const blob = blobs[blobs.length - 1];

                    if (!isFileValid(blob)) {
                      showErrorNotification(imageProblemMessage, true);
                      onRemove();
                    } else {
                      setFileBlob(blob);
                    }
                  }}
                />

                {!isNarrowScreen() && (
                  <button className='primary-button' onClick={() => setShowPhotoModal(true)}>
                    Take photo
                  </button>
                )}
              </div>
            )}
          </BottomBar>
        </Form>
      )}

      <PhotoModal open={showPhotoModal} setIsOpen={setShowPhotoModal} onNext={onClose} />
    </Layout>
  );
}
