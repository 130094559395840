import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { ProductCleanNameDosage } from './';
import { ProductAndFrequency } from 'client/dist/generated/alloy';

/**
 * get the name for all products combined from alloy product. this allows us to get dosage and then
 * formatting it to the user.
 *
 * @param groupedProduct GroupedContentfulProduct
 * @returns string
 */
export const getProductNameWithDosage = (
  groupedProduct: GroupedContentfulProduct
): ProductCleanNameDosage[] => {
  const { alloyProduct } = groupedProduct;
  const allProducts = [...alloyProduct.parent, ...(alloyProduct.child ?? [])].flat();

  return allProducts.map((p) => ({
    cleanName: p.cleanName,
    ...(p.dosage && {
      dosage: p.dosage,
    }),
  }));
};
