import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';
import { startRenewalAlert, useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { format } from 'date-fns';
import { convertNumberToWords } from 'lib/core/convertNumberToWords';
import { minBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ProductCardBlock from '../shared/ProductCard';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

export default function ExpiringCardBlock() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  const expiringProducts = activeSubs
    .flatMap((sub) => sub.products)
    .filter((pfr) => !!pfr.renewal && pfr.renewal.needsRenewed && pfr.renewal.expirationDate);

  const nextExpiryRenewal = minBy(
    expiringProducts.map((pfr) => pfr.renewal),
    (renewal) => new Date(renewal?.expirationDate!)
  );

  const onRenew = () => {
    startRenewalAlert({ productNames: expiringProducts.map((pfr) => pfr.product.name) });

    dispatchUpdateToNewCheckoutExperience(['renewal'], history);
  };

  if (!nextExpiryRenewal) {
    return <></>;
  }

  return (
    <ProductCardBlock
      hugText='action required'
      isRequired
      title='Treatments expiring soon'
      description={`${convertNumberToWords(
        expiringProducts.length
      )} of your treatments expire on ${format(
        new Date(nextExpiryRenewal.expirationDate!),
        'MMM d'
      )}. Renew to maintain access to your doctor.`}
      products={expiringProducts}
      maxProducts={2}
    >
      <button className='primary-button mt-3' onClick={onRenew}>
        Renew your treatment plan
      </button>
    </ProductCardBlock>
  );
}
