import {
  SubscriptionWithRenewal,
  startRenewalAlert,
  useGenerateSetupIntent,
  useGetAllSubscriptionsForCustomer,
  useGetPaymentMethods,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';

import { Skeleton } from '@mui/material';
import { getPrescriptionsStatusFrom } from 'lib/dashboard/prescription';
import { getExpiringPrescriptionsFromShipment } from 'lib/dashboard/subscription';

import ProductsList from 'components/dashboard/shared/products/ProductsList';
import HeaderInfoCardBlock from '../cards/HeaderInfoCard';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

export default function ManageWrapper() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id?: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<SubscriptionWithRenewal | null>(null);

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const {
    data: isProcessing,
    isLoading: isLoadingProcessing,
    error,
  } = useIsProcessing(params.id!!);
  const { isLoading: isLoadingIntent } = useGenerateSetupIntent();
  const { isLoading: isPaymentMethodsLoading } = useGetPaymentMethods();

  const isLoadingData =
    isLoading ||
    isLoadingSubscriptions ||
    isLoadingProcessing ||
    isLoadingIntent ||
    isPaymentMethodsLoading;

  const canEditProduct = process.env.REACT_APP_SHOW_V3_SUBSCRIPTIONS_BUNDLING === 'true';

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  useEffect(() => {
    refreshSubscriptionSearch();
  }, [JSON.stringify(subscriptions), isLoadingSubscriptions]);

  useEffect(() => {
    if (isProcessing || error) {
      history.replace('/subscriptions');
    }
  }, [isProcessing, error]);

  const refreshSubscriptionSearch = () => {
    setIsLoading(true);

    let subscriptionId = params.id;

    if (!!subscriptionId && !isLoadingSubscriptions) {
      const subscriptionFound = subscriptions.find(
        (item) => item.stripeSubscriptionId === subscriptionId
      );

      if (!!subscriptionFound && subscriptionFound.status === 'ACTIVE') {
        const { allPrescriptionsActive, prescriptionsNeedRenewal } =
          getPrescriptionsStatusFrom(subscriptionFound);

        if (!allPrescriptionsActive || prescriptionsNeedRenewal) {
          const productFrequencies = getExpiringPrescriptionsFromShipment(subscriptionFound);

          const productNames = productFrequencies.map((pf) => pf.name);

          startRenewalAlert({ productNames });

          dispatchUpdateToNewCheckoutExperience(['renewal'], history);
        } else {
          setSubscription(subscriptionFound);
          setIsLoading(false);
        }
      } else {
        history.replace('/subscriptions');
      }
    }
  };

  const onEditProduct = (gcp: GroupedContentfulProduct) => {
    // TODO: in next pr edit drawer functionality!!!
  };

  if (isLoadingData) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (!!subscription) {
    return (
      <div className='row'>
        <div className='col-12'>
          <div className='subscription-container'>
            <div className='subscription-content-wrapper'>
              <HeaderInfoCardBlock subscription={subscription} />
            </div>

            <div className='subscription-content-wrapper'>
              <div className='products-card-block'>
                <h3 className='products-title'>
                  Your Treatments <span className='title-hug'>Every 3 months</span>
                </h3>

                <ProductsList
                  productsWithRenewal={subscription.products}
                  subscription={subscription}
                  showDosage
                  groupType='mht'
                  onEdit={canEditProduct ? onEditProduct : undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
