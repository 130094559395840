import {
  ALLOY_CART,
  BILLING_ADDRESS,
  CHECKOUT,
  CHECKOUT_EDITING_SHIPPING,
  COGNITO_USER,
  LICENSE_EXISTS,
  LOCAL_USER,
  REQUESTING_PRODUCTS,
  SUGGESTIONS,
} from 'actions/types';
import { Checkout, IntakeCategory, Suggestions } from 'client/dist/generated/alloy';
import { ExperienceCategory } from 'common/dist/models/experience';
import { AlloyCartWithTerms } from 'models/alloy/cart';

import { Address } from 'pages/checkout-experience/shared/Checkout';

export type RequestingProductIds = number[];

export type ExperienceValidation = {
  categories: ExperienceCategory[];
  date: string;
  stepsViewed: string[];
};

export type LocalPreCustomer = Partial<{
  firstName: string;
  state: string;

  marketingEmailConsent: boolean;

  dateOfBirth: string;

  alloySubmissionId: string;
  previousCategoriesForSubmission: IntakeCategory[];
  recentSubmissionDate: string;

  experienceValidation: ExperienceValidation;
}>;

export interface CognitoUser {
  email: string;
  password: string;
}

export interface ExperienceState {
  requestingProductIds: RequestingProductIds;

  licenseExists: boolean;

  // TODO: Go away as pages built out
  suggestions: Suggestions;

  alloyCart: AlloyCartWithTerms;

  cognitoUser?: CognitoUser; // TODO does this need to be in redux..?
  // note - localPreCustomer is stored and updated both in memory
  // and in localstorage.
  localPreCustomer: LocalPreCustomer;

  checkout?: Checkout;

  isEditingShipping: boolean;
  billingAddress: Address;
}

const INIT_STATE: ExperienceState = {
  requestingProductIds: [],

  licenseExists: false,

  suggestions: {
    qualified: [],
    disqualified: [],
    symptoms: [],
  },

  alloyCart: {
    products: [],
    promotionCode: {
      id: '',
      name: '',
      discountAmount: 0,
    },
    taxAmountToCollect: 0,
    shipping: undefined,
    referralCreditBalance: 0,
    agreement: {
      isAgreedTerms: false,
      isOptedSmsMarketing: true,
    },
    isPurchased: false,
    shipNow: true,
  },

  cognitoUser: undefined,
  localPreCustomer: {},

  isEditingShipping: false,
  billingAddress: {},
};

// Create reducer
export default function ExperienceReducer(
  state = INIT_STATE,
  action: { payload: Partial<ExperienceState>; type: string }
) {
  switch (action.type) {
    case REQUESTING_PRODUCTS: {
      return { ...state, requestingProductIds: action.payload };
    }

    case LICENSE_EXISTS: {
      return { ...state, licenseExists: action.payload };
    }

    case SUGGESTIONS: {
      return { ...state, suggestions: action.payload };
    }

    case ALLOY_CART: {
      return { ...state, alloyCart: action.payload };
    }

    case COGNITO_USER: {
      return { ...state, isAuthenticated: false, cognitoUser: action.payload };
    }

    // MARK: This is staying for right now.
    case LOCAL_USER: {
      return { ...state, localPreCustomer: action.payload };
    }

    case CHECKOUT_EDITING_SHIPPING: {
      return { ...state, isEditingShipping: action.payload };
    }

    case BILLING_ADDRESS: {
      return { ...state, billingAddress: action.payload };
    }

    case CHECKOUT: {
      return { ...state, checkout: action.payload };
    }

    default:
      return state;
  }
}
