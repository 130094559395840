import { EXPERIENCE_REMOVE_REVIEW_TESTING } from 'actions/types';
import { shouldRemoveReviewStep } from 'lib/core/abTests';
import { Dispatch } from 'redux';

const initABTesting = () => {
  return async (dispatch: Dispatch) => {
    // need this no matter what if user is logged in or not
    // MARK: test fetching from apps/dashboard/src/lib/core/abTests.ts
    // goes below along with dispatches for said tests

    const removeReviewStep = shouldRemoveReviewStep();

    dispatch({ type: EXPERIENCE_REMOVE_REVIEW_TESTING, payload: removeReviewStep });
  };
};

export { initABTesting };
