import {
  SubscriptionWithRenewal,
  pauseSubscription,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Loader, Modal, Radio, RadioGroup } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

import { showSuccessNotification } from 'components/core/Notification';
import { TextAreaField } from 'components/core/fields/TextAreaField';

interface Props {
  shipment: SubscriptionWithRenewal;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const optionsText: string[] = [
  "I'm not feeling better",
  "My treatments aren't covered by insurance",
  'A doctor told me not to take it',
  'I want to try a different product',
  "It's too expensive",
  "I've had a poor experience with Alloy",
  "I don't want a subscription",
  'Other',
];

export default function PauseModal({ shipment, open, setOpen }: Props) {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const [optionSelected, setOptionSelected] = useState<number | undefined>();
  const [reasonPausing, setReasonPausing] = useState<string>('');

  const { mutate } = useGetAllSubscriptionsForCustomer();

  const onUpdateSelection = (text: string, index: number) => {
    setOptionSelected(index);
    setReasonPausing(index === optionsText.length - 1 ? '' : text);
  };

  const onConfirm = async () => {
    setLoading(true);

    await pauseSubscription(shipment.stripeSubscriptionId, { reason: reasonPausing });
    await mutate();

    showSuccessNotification('Your subscription has been paused');

    history.replace('/subscriptions');
  };

  return (
    <Modal
      open={open}
      size='sm'
      className='shipment-modal'
      onClose={() => {
        if (!loading) setOpen(false);
      }}
    >
      <Modal.Body>
        <div className='shipment-modal-header'>
          <button onClick={() => setOpen(false)} className='header-btn-close' disabled={loading}>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        <div className='shipment-modal-body'>
          <p className='modal-title'>Are you sure you'd like to pause?</p>
          <p className='modal-text'>Please select your reason for pausing.</p>

          <div className='modal-btn-wrapper'>
            {loading ? (
              <Loader size='md' />
            ) : (
              <>
                <div className='modal-select-options-list'>
                  <Form>
                    <RadioGroup name='pause-reason-options' value={optionSelected}>
                      {optionsText.map((text, index) => (
                        <Radio
                          key={index}
                          value={index}
                          className='option-radio'
                          onChange={(value) => onUpdateSelection(text, value as number)}
                        >
                          {text}
                        </Radio>
                      ))}
                    </RadioGroup>

                    {optionSelected === optionsText.length - 1 && (
                      <TextAreaField
                        name='other-reason'
                        rows={3}
                        onChange={(value) => setReasonPausing(value as string)}
                        placeholder='Additional comments'
                      />
                    )}
                  </Form>
                </div>
                <button className='btn-confirm' onClick={onConfirm} disabled={reasonPausing === ''}>
                  Submit request
                </button>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
