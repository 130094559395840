import TitleTextWrapper from 'components/dashboard/shared/wrappers/TitleTextWrapper';
import SubscriptionsWrapper from 'components/dashboard/subscriptions/wrappers/SubscriptionsWrapper';

import DashboardContainer from 'containers/dashboard/DashboardContainer';
import useMarkNotificationsAsRead from 'lib/dashboard/notifications/useMarkNotificationsAsRead';

export default function Subscriptions() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['PRESCRIPTION_AUTOPAUSED']);

  return (
    <DashboardContainer title='Subscriptions | Alloy' desc='' currentPage='subscriptions'>
      <section className='dashboard-section'>
        <div className='container'>
          <TitleTextWrapper title='Subscriptions' />

          <SubscriptionsWrapper />
        </div>
      </section>
    </DashboardContainer>
  );
}
