import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { xor } from 'lodash';
import { useEffect, useState } from 'react';

import SelectableProduct from '../products/SelectableProduct';

import AlloyDrawer from 'components/core/drawers/AlloyDrawer';
import BundleSelectableProduct from '../products/BundleSelectableProduct';
import { getProductIdsFromGroupedProducts, getProductToBeBundledWith } from 'lib/shared/product';
import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useAppSelector } from 'reducers/alloy_reducer';
import { isConsultCart } from 'lib/checkout-experience/checkout/cart';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

type ProductsWithType = {
  [key: string]: GroupedContentfulProduct[][];
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: (products: GroupedContentfulProduct[][]) => void;
  productsWithType: ProductsWithType;
  prevSelectedProducts: GroupedContentfulProduct[];
}

export default function EditDrawer({
  open,
  setOpen,
  onSave,
  productsWithType,
  prevSelectedProducts,
}: Props) {
  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const cart = useAppSelector((state) => state.experience.alloyCart);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer({
    swr: { enabled: isAuthenticated },
  });
  const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);

  const subscribedProductIds = [...activeSubs, ...pausedSubs].flatMap((sub) =>
    sub.products.map((p) => p.product.productId)
  );

  const [selectedProductIds, setSelectedProductIds] = useState(
    getProductIdsFromGroupedProducts(prevSelectedProducts)
  );
  const [specificProducts, setSpecificProducts] = useState<GroupedContentfulProduct[][]>([]);

  const mhtProducts = productsWithType['mht'] ?? [];
  const upsellProducts = productsWithType['upsell'] ?? [];
  const specificProductsWithoutSub = specificProducts
    .map((gcpList) =>
      gcpList.filter(
        (gcp) =>
          !subscribedProductIds.some((pid) =>
            gcp.alloyProduct.parent.every((pf) => pf.productId === pid)
          )
      )
    )
    .filter((gcpList) => gcpList.length);
  const onlyConsultOnCart = isConsultCart(cart);

  useEffect(() => {
    fetchSpecificProducts();
  }, [JSON.stringify(specificProducts), cart.shipNow]);

  /**
   * Here we need to refetch specific products together with products from user active subs.
   * This is because we should filter out all active sub products from Edit Drawer
   * keeping all bundle config any product has with them.
   *
   * Example: if Tretinoin is in drawer and m4 is in an active sub, we should only show
   * Tretinoin in drawer but with bundled price and informing that its parent is m4.
   *
   * TODO: since we need to consider active sub products in various places, we should refactor
   * this logic in the future and think of how to reutilize the active sub logic everywhere it's needed.
   */
  const fetchSpecificProducts = async () => {
    const specificProducts = productsWithType['specific'] ?? [];

    if (specificProducts.length !== 0 && subscribedProductIds.length > 0) {
      const productIds = getProductIdsFromGroupedProducts(specificProducts.flat());
      // if ship now is selected for request experience, we should filter out subscribed products so we can get the unbundled
      const productsWithoutSubs = productIds.filter((id) => !subscribedProductIds.includes(id));
      const productsWithActiveSubs = cart.shipNow
        ? await ProductRegistry.get().getRecurringProductsForV2(productsWithoutSubs)
        : await ProductRegistry.get().getRecurringProductsForV2([
            ...productIds,
            ...subscribedProductIds,
          ]);

      setSpecificProducts(productsWithActiveSubs);
    } else {
      setSpecificProducts(specificProducts);
    }
  };

  const onSelectProduct = (product: GroupedContentfulProduct) => {
    setSelectedProductIds(
      xor(
        selectedProductIds,
        product.alloyProduct.parent.map((p) => p.productId)
      )
    );
  };

  const isParentSelected = (parent?: GroupedContentfulProduct) => {
    return (
      !!parent &&
      [...selectedProductIds, ...subscribedProductIds].some((pid) =>
        parent.alloyProduct.parent.every((pf) => pf.productId === pid)
      )
    );
  };

  const onSaveAndClose = () => {
    const products = Object.values(productsWithType).flatMap((gcp) => gcp);

    const filteredSelectedProducts = products
      .map((gcpList) =>
        gcpList.filter((gcp) =>
          selectedProductIds.some((pid) =>
            gcp.alloyProduct.parent.every((pf) => pf.productId === pid)
          )
        )
      )
      .filter((gcpList) => gcpList.length);

    onSave(filteredSelectedProducts);
  };

  return (
    <AlloyDrawer title='Edit selections' drawerClass='edit-drawer' open={open} setOpen={setOpen}>
      <div className='drawer-body'>
        {mhtProducts.length !== 0 && (
          <div className='edit-products-wrapper'>
            {mhtProducts.map((gcpList, listIndex) => {
              return gcpList.map((gcp, index) => {
                const isSelected = selectedProductIds.some((pid) =>
                  gcp.alloyProduct.parent.every((pf) => pf.productId === pid)
                );

                return (
                  <SelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    multiSelect
                    hideDetails
                    showProgDetails
                  />
                );
              });
            })}

            <div className='edit-divider' />
          </div>
        )}

        {upsellProducts.length !== 0 && (
          <div className='edit-products-wrapper'>
            <p className='edit-text'>Round out your routine</p>

            {upsellProducts.map((gcpList, listIndex) => {
              return gcpList.map((gcp, index) => {
                const isSelected = selectedProductIds.some((pid) =>
                  gcp.alloyProduct.parent.every((pf) => pf.productId === pid)
                );

                return (
                  <SelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    multiSelect
                    hideDetails
                  />
                );
              });
            })}
          </div>
        )}

        {specificProductsWithoutSub.length !== 0 && (
          <div className='edit-products-wrapper'>
            {specificProductsWithoutSub.map((gcpList, listIndex) => {
              return gcpList.map((gcp, index) => {
                const isSelected = selectedProductIds.some((pid) =>
                  gcp.alloyProduct.parent.every((pf) => pf.productId === pid)
                );
                const parent = getProductToBeBundledWith(
                  gcp,
                  cart.shipNow && specificProducts.flat().length === 1 ? [] : specificProducts
                );

                return !!parent ? (
                  <BundleSelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    isParentSelected={isParentSelected(parent)}
                    parent={parent}
                    hideDetails
                  />
                ) : (
                  <SelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    multiSelect
                    hideDetails
                  />
                );
              });
            })}
          </div>
        )}
      </div>

      <div className='drawer-footer'>
        <button
          className='primary-button'
          disabled={selectedProductIds.length === 0 && !onlyConsultOnCart}
          onClick={onSaveAndClose}
        >
          Save {selectedProductIds.length} selection
          {selectedProductIds.length !== 1 && 's'}
        </button>
      </div>
    </AlloyDrawer>
  );
}
