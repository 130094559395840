import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { ProductFrequencyRenewal, SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { RecurrenceType } from 'common/dist/products/productFrequency';
import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';

import alloyProductFiller from 'assets/images/shared/alloy-product-filler.png';

import { convertCentsToDollars } from 'lib/shared/convert';
import { getProductNameWithDosage, getReadablePrice } from 'lib/shared/product';
import { getGroupedContentfulProductsBy } from 'lib/shared/product/contentful';

interface Props {
  subscription?: SubscriptionWithRenewal;
  productsWithRenewal: ProductFrequencyRenewal[];
  showDosage?: boolean;
  shouldGroup?: boolean;
  groupType: 'all' | 'mht';

  onEdit?: (gcp: GroupedContentfulProduct) => void;
}

export default function ProductsList({
  subscription,
  productsWithRenewal,
  showDosage = false,
  shouldGroup = true,
  groupType,
  onEdit,
}: Props) {
  const [products, setProducts] = useState<GroupedContentfulProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const disableList = !!subscription && subscription.status !== 'ACTIVE';

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    if (shouldGroup) {
      const groupedContentfulProducts = await getGroupedContentfulProductsBy(
        productsWithRenewal.map((pfr) => pfr.product),
        groupType === 'all'
      );

      setProducts(groupedContentfulProducts);
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className='products-list-wrapper'>
        <div className='product-block'>
          <div className='product-img-wrapper'>
            <Skeleton variant='rectangular' width={100} height={100} />
          </div>

          <div className='product-content-wrapper'>
            <Skeleton variant='text' width={150} />

            <Skeleton variant='text' width={100} />
          </div>
        </div>
      </div>
    );
  }

  if (!shouldGroup) {
    return (
      <div className={classNames('products-list-wrapper', disableList && 'disable')}>
        {productsWithRenewal.map((pfr, index) => {
          const prescription = pfr.prescription;
          const prescriptionActiveSub = !!prescription && subscription?.status === 'ACTIVE';

          return (
            <div className='product-block' key={index}>
              <div className='product-img-content-wrapper'>
                <div className='product-img-wrapper'>
                  <img src={alloyProductFiller} alt={pfr.product.name} className='product-img' />
                </div>

                <div className='product-content-wrapper'>
                  <p className='content-title'>{pfr.product.name}</p>

                  <p className='content-text'>${convertCentsToDollars(pfr.product.priceInCents)}</p>

                  {prescriptionActiveSub && (
                    <p className='content-text'>
                      {prescription.fillsRemaining} refill
                      {prescription.fillsRemaining !== 1 && 's'} left
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classNames('products-list-wrapper', disableList && 'disable')}>
      {products.map((gcp, index) => {
        const recurrenceType = gcp.alloyProduct.parent[0].recurrenceType;

        const prescription = !!subscription
          ? subscription.products.find((p) =>
              gcp.alloyProduct.parent.map((pf) => pf.id).includes(p.product.id)
            )?.prescription
          : undefined;

        const namesAndDosage = showDosage
          ? getProductNameWithDosage(gcp)
          : [{ cleanName: gcp.contentfulProduct.fields.title }];

        const priceInCents = sumBy(
          [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child ?? [])],
          (pf) => pf.priceInCents
        );

        const prescriptionActiveSub = !!prescription && subscription?.status === 'ACTIVE';
        const prescriptionInactiveSub = !!prescription && subscription?.status !== 'ACTIVE';

        return (
          <div className={classNames('product-block', !!onEdit && 'add-border')} key={index}>
            <div className='product-img-content-wrapper'>
              <div className='product-img-wrapper'>
                {!gcp.contentfulProduct.fields.categories.every(
                  (category) => category === 'gut-health'
                ) && <span className='product-rx-tag'>rx</span>}

                <img
                  src={gcp.contentfulProduct.fields.photo.fields.file.url}
                  alt={gcp.contentfulProduct.fields.title}
                  className='product-img'
                />
              </div>

              <div className='product-content-wrapper'>
                {namesAndDosage.map(({ cleanName, dosage }, pIndex) => (
                  <p className='content-title' key={pIndex}>
                    {cleanName}
                    {!!dosage && <span className='title-dosage-tag'>{dosage}</span>}
                  </p>
                ))}

                <p className='content-text'>
                  ${getReadablePrice(priceInCents).toFixed(2)} (
                  {recurrenceType === RecurrenceType.RECURRING ? '3-month supply' : 'One-time fill'}
                  )
                </p>

                {prescriptionActiveSub && (
                  <p className='content-text d-none d-md-flex'>
                    {prescription.fillsRemaining} refill
                    {prescription.fillsRemaining !== 1 && 's'} left
                  </p>
                )}

                {prescriptionInactiveSub && (
                  <p className='content-text d-none d-md-flex'>
                    Refills: {prescription.fillsRemaining}
                    <span className='text-tag'>Paused</span>
                  </p>
                )}
              </div>
            </div>

            {!!onEdit && (
              <div className='product-action-wrapper'>
                <button className='action-link' onClick={() => onEdit(gcp)}>
                  Edit
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
