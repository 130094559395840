import {
  CheckoutType,
  ProductAndFrequency,
  Submission,
  SubmissionAnswer,
  postSubmission,
  processMhtChange,
  runSuggestions,
  useGetAllSubscriptionsForCustomer,
  useGetPendingMhtSwitch,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import QuestionnaireRoutes from 'components/checkout-experience/questionnaire/QuestionnaireRoutes';
import Loader from 'components/core/Loader';
import Disqualified from 'components/request-experience/questionnaire/Disqualified';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { collateHiddenSubmissionAnswers } from 'lib/checkout-experience/questionnaire/intake';
import { cleanPurchasableProducts, retrieveFlowFromUrl } from 'lib/request-experience/flow';
import { getIntakeCategories, retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { getPreviousResults } from 'lib/shared/questionnaire';

import { ExperienceComponentModel } from 'models/alloy/experience';
import { QuestionnaireAnswers } from 'models/components/questionnaire';

import { useAppSelector } from 'reducers/alloy_reducer';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

export default function Intake({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const dateStarted = new Date();

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);

  const intakeQuestions = flow.intakeQuestions!!;

  const [previousResults, setPreviousResults] = useState<QuestionnaireAnswers>({});
  const [isLoading, setIsLoading] = useState(true);
  const [disqualifiedProducts, setDisqualifiedProducts] = useState<ProductAndFrequency[]>([]);

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const cart = useAppSelector((state) => state.experience.alloyCart);

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutatePendingMhtSwitch } = useGetPendingMhtSwitch();

  useEffect(() => {
    const previousResults = getPreviousResults(intakeQuestions);

    setPreviousResults(previousResults);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (categories.every((c) => c === 'mht') && cart.products.length === 0) {
      history.replace('/');
    }
  }, []);

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const intakeCategories = getIntakeCategories(categories);
      const questions = flow.intakeQuestions!!;

      const updatedAnswers = collateHiddenSubmissionAnswers(
        answers,
        questions,
        localPreCustomer,
        customer
      );

      const submission: Submission = {
        categories: intakeCategories,
        started: dateStarted.toISOString(),
        answers: updatedAnswers,
      };

      const { id } = await postSubmission(submission);

      // for mht flow, we need to handle the completion a little differently since it is a mht
      // change vs a direct request of a product
      if (categories.every((c) => c === 'mht')) {
        await handleMhtFlow(id);
      } else {
        await handleNonMhtFlow(id);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // handles the mht change processing
  const handleMhtFlow = async (submissionId?: string) => {
    setIsLoading(true);

    const searchParams = new URLSearchParams(location.search);

    const checkoutType = searchParams.get('checkoutType');
    const currentMhtPfIds = searchParams.get('currentMhtPfIds[]');
    const requestedMhtPfIds = searchParams.get('requestedMhtPfIds[]');

    // this shouldn't happen since we check for this to exist in url before even
    // getting into mht request experience
    if (!currentMhtPfIds || !requestedMhtPfIds || !checkoutType) {
      setIsLoading(false);
      return;
    }

    const convCurrentMhtPfIds = currentMhtPfIds.split(',').map((id) => Number(id));
    const convRequestedMhtPfIds = requestedMhtPfIds.split(',').map((id) => Number(id));

    await processMhtChange({
      checkoutType: checkoutType as CheckoutType,
      currentMhtPfIds: convCurrentMhtPfIds,
      requestedMhtPfIds: convRequestedMhtPfIds,
      submissionId,
    });

    await mutatePendingMhtSwitch();

    history.push({
      pathname: '/request-confirmation',
      search: `?${searchParams.toString()}`,
    });
  };

  // moved this out of the submit func for non mht

  /**
   * request-experience starts with 'default' products based on getEligibleUpsellProducts returned from productSerivce
   *
   * we pass different values to the cart between RE and CE because of the inital
   * product selection offered from each experience by which ensures appropriate dosages are selected
   */
  const handleNonMhtFlow = async (submissionId?: string) => {
    // TODO: some suggestions for tret and others should be here
    if (submissionId) {
      const suggestions = await runSuggestions({ submissionId });

      // Handle cleaning requested products, this can be particular for the piece around m4 in subs
      // but wants to request tret and needs to see cheaper price
      const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);
      const activeSubscriptions = [...activeSubs, ...pausedSubs];

      const activeSubsProductIds = activeSubscriptions.flatMap((sub) =>
        sub.products.map((pfr) => pfr.product.productId)
      );

      const qualifiedProductIds = suggestions.qualified
        .map((dq) => dq.product.productId)
        .filter((id) => !activeSubsProductIds.includes(id));

      if (qualifiedProductIds.length === 0) {
        setDisqualifiedProducts(suggestions.disqualified.map((dq) => dq.product));
        setIsLoading(false);
        return;
      }

      const filteredProducts = await cleanPurchasableProducts(
        qualifiedProductIds,
        activeSubsProductIds
      );

      dispatchUpdateCart({
        products: filteredProducts,
      });
    }

    onNext();
  };

  if (isLoading) {
    return (
      <Layout title='Intake - Alloy' desc='' noBars>
        <TopBannerWithProgress percentComplete={getPercentComplete(location)} />

        <Loader />
      </Layout>
    );
  }

  if (disqualifiedProducts.length !== 0) return <Disqualified products={disqualifiedProducts} />;

  return (
    <QuestionnaireRoutes
      questions={intakeQuestions}
      previousResults={previousResults}
      onSubmit={onSubmit}
      onBack={onBack}
      parentUrlPath='/request-experience/intake'
    />
  );
}
