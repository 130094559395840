import { Elements } from '@stripe/react-stripe-js';
import {
  Customer,
  useGenerateSetupIntent,
  useGetAllSubscriptionsForCustomer,
  useGetCustomerReferralCreditBalance,
  useGetNonCustomerUpsellProducts,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { processCheckout } from 'actions/checkout-experience/checkout_actions';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import CheckoutSection from 'components/shared/checkout/CheckoutSection';
import Loader from 'components/core/Loader';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { stripePromise } from 'lib/shared/payment';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { useAppSelector } from 'reducers/alloy_reducer';
import { updateCart } from 'actions/checkout-experience/cart_actions';
import sendAbandonEvent from 'lib/tracking/sendAbandonEvent';
import { retrieveProductIdsFromUrl } from 'lib/request-experience/flow';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

// TODO: In clean up move this to be location!
export type Address = Pick<
  Customer,
  | 'firstName'
  | 'lastName'
  | 'shippingAddressLineOne'
  | 'shippingAddressLineTwo'
  | 'city'
  | 'stateAbbr'
  | 'zip'
  | 'phoneNumber'
>;

export default function Checkout({ onBack }: Omit<ExperienceComponentModel, 'onNext'>) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const cart = useAppSelector((state) => state.experience.alloyCart);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isLoading: isLoadingReferralCredits } = useGetCustomerReferralCreditBalance();
  const { isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();
  const { mutate: mutateUpsellProducts } = useGetNonCustomerUpsellProducts();
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const isAnyLoading =
    isLoading || isLoadingReferralCredits || isLoadingPaymentMethods || isLoadingSetupIntent;

  const dispatchProcessCheckout = bindActionCreators(processCheckout, dispatch);
  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  useEffect(() => {
    // default the cart terms to accepted since they have previously purchased!
    dispatchUpdateCart({
      agreement: {
        ...cart.agreement,
        isAgreedTerms: true,
      },
      shipNow: !activeSubs.length,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const categories = retrieveCategoriesFromUrl(location);
    const productIds = retrieveProductIdsFromUrl(location);

    // checkout shown with product
    sendAbandonEvent({
      event: 'CHECKOUT_SHOWN',
      categories,
      experience: 'request',
      productIds,
    });
  }, []);

  const onPlaceOrder = async () => {
    window.scrollTo(0, 0);

    try {
      setIsLoading(true);

      const categories = retrieveCategoriesFromUrl(location);
      const productIds = retrieveProductIdsFromUrl(location);

      await dispatchProcessCheckout(cart, categories, 'request');
      await mutateUpsellProducts();

      // checkout completed with product
      sendAbandonEvent({
        event: 'CHECKOUT_COMPLETED',
        categories,
        experience: 'request',
        productIds,
      });

      const searchParams = new URLSearchParams(location.search);

      history.push({
        pathname: '/request-confirmation',
        search: `?${searchParams.toString()}`,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Layout title='Checkout - Alloy' desc='' noBars>
      <TopBannerWithProgress
        onBack={isAnyLoading ? undefined : onBack}
        percentComplete={getPercentComplete(location)}
      />

      {isAnyLoading || !setupIntent ? (
        <Loader />
      ) : (
        <Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
          <CheckoutSection onPlaceOrder={onPlaceOrder} />
        </Elements>
      )}
    </Layout>
  );
}
